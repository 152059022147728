<template>
  <div class="app-box">
    <div class="borUl">
      <van-tabs
        v-model="active"
        color="#fff"
        title-active-color="#0E8AFFFF"
        animated
      >
        <van-tab title="全部">
          <p class="titleboe">
            *图片保存，复制文案，经常发朋友圈可明显提高成功率！
          </p>
          <div class="listBoxUl">
            <div class="li">
              <div class="left">
                <div clsas="colorCcc">
                  <span class="colo1">21</span>
                  <span class="colo2">08</span>
                  <span class="colo3">2021-08-23 00:00:00</span>
                  <div class="titleTxt">
                    共同营造良好有序的社群环境
                    <div class="right">
                      <van-button
                        type="primary"
                        size="small"
                        round
                        color="#EEF7FFFF"
                        @click="copyUrl('共同营造良好有序的社群环境')"
                        >复制</van-button
                      >
                    </div>
                  </div>
                  <div class="imgBox">
                    <div class="imgFlex">
                      <img src="https://img.menghn.com/imgpyq.jpg" alt="" />
                    </div>
                    <div class="imgFlex">
                      <img src="https://img.menghn.com/imgpyq.jpg" alt="" />
                    </div>
                    <div class="imgFlex">
                      <img src="https://img.menghn.com/imgpyq.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="li">
              <div class="left">
                <div clsas="colorCcc">
                  <span class="colo1">21</span>
                  <span class="colo2">08</span>
                  <span class="colo3">2021-08-23 00:00:00</span>
                  <div class="titleTxt">
                    共同营造良好有序的社群环境
                    <div class="right">
                      <van-button
                        type="primary"
                        size="small"
                        round
                        color="#EEF7FFFF"
                        @click="copyUrl('共同营造良好有序的社群环境')"
                        >复制</van-button
                      >
                    </div>
                  </div>
                  <div class="imgBox">
                    <div class="imgFlex imgFlex1">
                      <img src="https://img.menghn.com/imgpyq.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="li">
              <div class="left">
                <div clsas="colorCcc">
                  <span class="colo1">21</span>
                  <span class="colo2">08</span>
                  <span class="colo3">2021-08-23 00:00:00</span>
                  <div class="titleTxt">
                    共同营造良好有序的社群环境
                    <div class="right">
                      <van-button
                        type="primary"
                        size="small"
                        round
                        color="#EEF7FFFF"
                        @click="copyUrl('共同营造良好有序的社群环境')"
                        >复制</van-button
                      >
                    </div>
                  </div>
                  <div class="imgBox2">
                    <div class="imgFlex">
                      <img src="https://img.menghn.com/imgpyq.jpg" alt="" />
                    </div>
                    <div class="imgFlex">
                      <img src="https://img.menghn.com/imgpyq.jpg" alt="" />
                    </div>
                    <div class="imgFlex">
                      <img src="https://img.menghn.com/imgpyq.jpg" alt="" />
                    </div>
                    <div class="imgFlex">
                      <img src="https://img.menghn.com/imgpyq.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </van-tab>
        <van-tab title="推广">内容 2</van-tab>
        <van-tab title="招募">内容 3</van-tab>
        <van-tab title="其他">内容 4</van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      active: 0,
      listArr: [
        {
          time: "2021-08-23 00:00:00",
          txt: "共同营造良好有序的社群环境",
          img: "https://img.menghn.com/imgpyq.jpg",
        },
        {
          time: "2021-08-23 00:00:00",
          txt: "共同营造良好有序的社群环境22222222",
          img: "https://img.menghn.com/imgpyq.jpg",
        },
      ],
    };
  },
  methods: {
    gotfh() {
      this.$router.go(-1);
    },
    ImgEnlarge(item) {
      ImagePreview([item]);
    },
    copyUrl(url) {
      var domUrl = document.createElement("input");
      domUrl.value = url;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      Toast.success("复制成功");
    },
  },
};
</script>

<style scoped>
.app-box {
  min-height: 100vh;
  background-color: #edf2f5ff;
  box-sizing: border-box;
}
::v-deep.van-tabs__nav {
  border-bottom: 1px solid #d0d2daff;
}
.borUl {
  width: 100%;
}
.titleboe {
  color: #7f8499ff;
  padding: 5px 10px;
  box-sizing: border-box;
  font-size: 13px;
  background: #fff;
}
.listBoxUl {
  box-sizing: border-box;
  background: #fff;
}
.listBoxUl .li {
  /* display: flex; */
  position: relative;
  padding: 10px 10px;
  border-bottom: 10px solid #edf2f5ff;
}
.listBoxUl .li .left {
}
.listBoxUl .li .right {
  text-align: right;
  position: absolute;
  right: 0;
  top: -10px;
}
.colorCcc {
}
.colo1 {
  font-size: 20px;
  font-weight: bold;
  margin-right: 3px;
}
.colo2 {
  font-size: 18px;
  font-weight: bold;
  margin-right: 3px;
}
.colo3 {
  font-size: 15px;
  color: #999dadff;
}
.listBoxUl .left img {
  width: 100%;
}
.boerImg {
  width: 100%;
  /* max-height: 136px; */
  overflow: hidden;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
}
.imgFlex {
  flex: 1;
}
.titleTxt {
  font-size: 15px;
  margin: 5px 0;
  padding-right: 90px;
  position: relative;
}
.listBoxUl .right ::v-deep .van-button {
  color: #0e8affff !important;
  padding: 0 20px;
}
.imgBox {
  display: flex;
}
.imgBox .imgFlex {
  flex: 1;
  margin-right: 5px;
}
.imgBox .imgFlex img {
  border-radius: 8px;
  min-height: 75px;
}
.imgBox .imgFlex:last-child {
  margin-right: 0;
}
.imgFlex1 img{
  width: 113px !important;
}
.imgBox2{
  width: 70%;
  overflow: hidden;
}
.imgBox2 .imgFlex{
  width: 48%;
  float: left;
  margin-right: 8px;
  margin-top: 10px;
}
.imgBox2 .imgFlex img{
  border-radius: 8px;
  min-height: 75px;
  width: 100%;
}
.imgBox2 .imgFlex:nth-child(2){
  margin-right: 0;
}
.imgBox2 .imgFlex:nth-child(4){
  margin-right: 0;
}
::v-deep .van-nav-bar .van-icon {
		color: #000;
	}
</style>